import React, { useEffect, useRef, useState } from "react";

import "./QrStyles.css";
import QrScanner from "qr-scanner";
import QrFrame from "../../assets/images/qr-frame.svg";
import IRegistroPresencaRequest from "../../interfaces/IRegistroPresencaRequest.tsx";
import CallbackApiService from "../../services/CallbackApiService.tsx";
import { useDispatch, useSelector } from "react-redux";
import { errorState } from "../../redux/error/actions.ts";
import { loadingState } from "../../redux/loading/actions.ts";
import { registroPresencaState } from "../../redux/registro-presenca/actions.ts";
import IRegistroPresenca from "../../interfaces/IRegistroPresenca.tsx";
import { useNavigate } from "react-router-dom";

const QrReader = () => {

  const scanner = useRef<QrScanner>();
  const videoEl = useRef<HTMLVideoElement>(null);
  const qrBoxEl = useRef<HTMLDivElement>(null);
  const [qrOn, setQrOn] = useState<boolean>(true);
  const [cameraBloqueada, setCameraBloqueada] = useState<boolean>(false);
  const [scannedResult, setScannedResult] = useState<string | undefined>("");

  const { programacao } = useSelector((state: any) => state.programacaoReducer);
  const { monitoramento } = useSelector((state: any) => state.monitoramentoReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onScanSuccess = (result: QrScanner.ScanResult) => {
    setScannedResult(result?.data);
    setError("");
  };

  const onScanFail = (err: string) => {
    setError(err);
  };

  const stopQrScanner = () => {
    if (scanner.current) {
      scanner.current.stop();
      scanner.current.destroy();
      scanner.current = undefined;
      setQrOn(false);
    }
  };

  function setRegistroPresenca(registroPresenca: IRegistroPresenca) {
    dispatch(registroPresencaState({ registroPresenca: registroPresenca }));
  }

  function setError(message: string) {
    dispatch(errorState({ error: message }));
  }
  
  function setLoading(loading: boolean) {
    dispatch(loadingState({ loading: loading }));
  }

  function qrCodeValido(qrcode: string | undefined) {
    console.log("QrCode Lido:" + qrcode)
    if (!qrcode) {
      return false;
    }
    if (qrcode.length < 5) {
      setError("QrCode Invalido.")
      return false;
    }
    let camposQrCode = qrcode.split('=');
    if (camposQrCode.length == 12) {
      return validarQrCodeCartaoVirtual(camposQrCode);
    }
    if (camposQrCode.length == 5 && camposQrCode[0] == "QrE01") {
      return validarQrCodeCredencial(camposQrCode);
    }
    if (camposQrCode.length == 3) {
      return validarQrCodeColaborador(camposQrCode);
    }
    setError("QrCode Invalido(layout invalido).")
    return false;
  }

  function validarQrCodeColaborador(camposQrCode: string | any[]) {
    if (!camposQrCode[1]) {
      setError("QrCode Colaborador Invalido(cpf).")
      return false;
    }
    if (!camposQrCode[2] || camposQrCode[2].length < 5) {
      setError("QrCode Colaborador Invalido(nome).")
      return false;
    }
    return true;
  }

  function validarQrCodeCredencial(camposQrCode: string | any[]) {
    if (!camposQrCode[2]) {
      setError("QrCode Credencial Invalido(cpf).")
      return false;
    }
    if (!camposQrCode[3] || camposQrCode[3].length < 5) {
      setError("QrCode Credencial Invalido(nome).")
      return false;
    }
    return true;
  }

  function validarQrCodeCartaoVirtual(camposQrCode: string | any[]) {
    if (camposQrCode[1] !== "01") {
      setError("QrCode Cartão Virtual Invalido(versão).")
      return false;
    }
    if (camposQrCode[2].length !== 17) {
      setError("QrCode Cartão Virtual Invalido(carteira).")
      return false;
    }
    if (!camposQrCode[3] || camposQrCode[3].length < 5) {
      setError("QrCode Cartão Virtual Invalido(nome).")
      return false;
    }
    return true;
  }

  async function registrarPresenca() {
    try {
      if (qrCodeValido(scannedResult)) {
        let requestData = {} as IRegistroPresencaRequest;
        requestData.programacaoId = programacao.programacaoId;
        requestData.qrCode = scannedResult;
        setLoading(true);
        const data = await CallbackApiService.registrarPresencaPorQrCode(requestData);
        setRegistroPresenca(data);
        if (monitoramento === "OPERADOR") {
          navigate("/registro-presenca");
        } else {
          navigate("/registro-presenca?proc=auto");
        }
      }
    } catch (error) {
      stopQrScanner();
      console.log("Error:" + error);
      setError(error.message);
      navigate("/");
    } finally {
      setLoading(false);
      setScannedResult("");
    }
  }

  useEffect(() => {
    if (!programacao.programacaoId) {
      setError("Selecione a programação de uma atividade evento em andamento no momento");
      navigate("/configuracoes");
    } else {
      registrarPresenca();
    }
  }, [scannedResult]);

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      // 👉 Instantiate the QR Scanner
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: onScanFail,
        // 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
        preferredCamera: "environment",
        // 🖼 This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
        highlightScanRegion: true,
        // 🔥 This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
        highlightCodeOutline: true,
        // 📦 A custom div which will pair with "highlightScanRegion" option above 👆. This gives us full control over our scan region.
        overlay: qrBoxEl?.current || undefined,
      });

      // 🚀 Start QR Scanner
      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          console.error("Erro ao iniciar o scanner:", err); 
          if (err) {
            setCameraBloqueada(true);
            setQrOn(false);
          }
        });
    }

    // 🧹 Clean up on unmount.
    // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
    return () => {
      stopQrScanner();
    };
  }, []);

  // ❌ If "camera" is not allowed in browser permissions, show an alert.
  useEffect(() => {
    if (cameraBloqueada) {
      alert("Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload.");
      setError("");
      navigate("/");
    }
  }, [qrOn]);

  return (
    <div className="qr-reader">
      {/* QR */}
      <video ref={videoEl}></video>
      <div ref={qrBoxEl} className="qr-box">
        <img
          src={QrFrame}
          alt="Qr Frame"
          width={256}
          height={256}
          className="qr-frame"
        />
      </div>
    </div>
  );
};

export default QrReader;