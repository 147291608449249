
import React, { useEffect } from 'react'
import {Route, Routes, useLocation } from "react-router-dom";
import keycloak from "./KeycloakProvider";
import './App.css';
import RenderOnRole from "./componentes/RenderOnRole";
import PaginaGestaoEventos from './pages/gestaoeventos/PaginaGestaoEventos.tsx';
import { PaginaInscricaoEvento } from './pages/inscricao/PaginaInscricaoEvento.tsx';
import { PaginaSuaInscricao } from './pages/inscricao/PaginaSuaInscricao.tsx';
import PainelConfiguracoes from './pages/gestaoeventos/PainelConfiguracoes.tsx';
import PainelListaDePresenca from './pages/gestaoeventos/PainelListaDePresenca.tsx';
import PainelCrudEvento from './pages/crud-evento/PainelCrudEvento.tsx';
import PainelFormEvento from './pages/crud-evento/PainelFormEvento.tsx';
import PainelCrudGevPessoa from './pages/crud-pessoa/PainelCrudGevPessoa.tsx';
import PainelFormGevPessoa from './pages/crud-pessoa/PainelFormGevPessoa.tsx';
import PainelCrudProgramacao from './pages/crud-evento/PainelCrudProgramacao.tsx';
import PainelFormProgramacao from './pages/crud-evento/PainelFormProgramacao.tsx';
import PainelInscricoes from './pages/crud-evento/PainelInscricoes.tsx';
import PainelRegistroPresenca from './pages/gestaoeventos/PainelRegistroPresenca.tsx';
import PainelRegistroManual from './pages/gestaoeventos/PainelRegistroManual.tsx';
import QrReader from './componentes/QrCode/QrReader.tsx';

function App() {

  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (!keycloak.authenticated && !(path || "").includes("inscricao-evento") && !(path || "").includes("sua-inscricao")) {
      keycloak.login();
    }
  }, [keycloak]);
      
  return (
    <div id="wrapper ">
        <Routes>
          <Route exact path="/" element={
            <RenderOnRole roles={['usuario']} showNotAllowed>
              <PaginaGestaoEventos />
            </RenderOnRole>
          }>
            <Route path="/configuracoes" element={
              <RenderOnRole roles={['usuario']} showNotAllowed>
                <PainelConfiguracoes />
              </RenderOnRole>
              }
            />
              <Route path="/configuracoes/cadastro-evento" element={
                <RenderOnRole roles={['admin-evento', 'admin-evento']} showNotAllowed>
                  <PainelCrudEvento />
                </RenderOnRole>
                }
              />
                <Route path="/configuracoes/cadastro-evento/programacao/:idEventoParam" element={
                  <RenderOnRole roles={['admin-evento', 'admin-evento']} showNotAllowed>
                    <PainelCrudProgramacao />
                  </RenderOnRole>
                  }
                />
                <Route path="/configuracoes/cadastro-evento/programacao/form/" element={
                  <RenderOnRole roles={['admin-evento', 'admin-evento']} showNotAllowed>
                    <PainelFormProgramacao />
                  </RenderOnRole>
                  }
                />
                <Route path="/configuracoes/cadastro-evento/programacao/form/:idProgramacao" element={
                  <RenderOnRole roles={['admin-evento', 'admin-evento']} showNotAllowed>
                    <PainelFormProgramacao />
                  </RenderOnRole>
                  }
                />
                <Route path="/configuracoes/cadastro-evento/inscricoes/:idEventoParam" element={
                  <RenderOnRole roles={['admin-evento', 'admin-evento']} showNotAllowed>
                    <PainelInscricoes />
                  </RenderOnRole>
                  }
                />
                <Route path="/configuracoes/cadastro-evento/form" element={
                  <RenderOnRole roles={['admin-evento', 'admin-evento']} showNotAllowed>
                    <PainelFormEvento />
                  </RenderOnRole>
                  }
                />
                <Route path="/configuracoes/cadastro-evento/form/:idEvento" element={
                  <RenderOnRole roles={['admin-evento', 'admin-evento']} showNotAllowed>
                    <PainelFormEvento />
                  </RenderOnRole>
                  }
                />
            <Route path="/configuracoes/cadastro-pessoa" element={
              <RenderOnRole roles={['admin-evento', 'admin-evento']} showNotAllowed>
                <PainelCrudGevPessoa />
              </RenderOnRole>
              }
            />
            <Route path="/configuracoes/cadastro-pessoa/form" element={
              <RenderOnRole roles={['admin-evento', 'admin-evento']} showNotAllowed>
                <PainelFormGevPessoa />
              </RenderOnRole>
              }
            />
            <Route path="/configuracoes/cadastro-pessoa/form/:idPessoa" element={
              <RenderOnRole roles={['admin-evento', 'admin-evento']} showNotAllowed>
                <PainelFormGevPessoa />
              </RenderOnRole>
              }
            />
            <Route path="/lista-presenca" element={
              <RenderOnRole roles={['usuario']} showNotAllowed>
                <PainelListaDePresenca />
              </RenderOnRole>
              }
            />
            <Route path="/registro-presenca" element={
              <RenderOnRole roles={['usuario']} showNotAllowed>
                <PainelRegistroPresenca />
              </RenderOnRole>
              }
            />
            <Route path="/registro-presenca-qr" element={
              <RenderOnRole roles={['usuario']} showNotAllowed>
                <QrReader />
              </RenderOnRole>
              }
            />
            <Route path="/registro-presenca-manual" element={
              <RenderOnRole roles={['usuario']} showNotAllowed>
                <PainelRegistroManual />
              </RenderOnRole>
              }
            />
          </Route>
          <Route
            path="/index"
            element={
              <RenderOnRole roles={['usuario']} showNotAllowed>
                <PaginaGestaoEventos />
              </RenderOnRole>
            }
          />
          <Route
            path="/inscricao-evento"
            element={<PaginaInscricaoEvento />}
          />
          <Route
            path="/sua-inscricao"
            element={<PaginaSuaInscricao />}
          />
        </Routes>
      </div>
  );
}

export default App;