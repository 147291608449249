import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import CallbackApiService from "../../services/CallbackApiService.tsx";
import IEvento from '../../interfaces/IEvento.tsx';
import SelectPublicoAlvo from './SelectPublicoAlvo.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { errorState } from '../../redux/error/actions.ts';
import { loadingState } from '../../redux/loading/actions.ts';

const PainelFormEvento = () => {

    const [validated, setValidated] = useState(false);
    const [id, setId] = useState<number | undefined>();
    const [descricao, setDescricao] = useState<string | undefined>("");
    const [tipoEvento, setTipoEvento] = useState<string | undefined>("");
    const [tipoParticipacao, setTipoParticipacao] = useState<string | undefined>("");
    const [tipoRegistroPresenca, setTipoRegistroPresenca] = useState<string | undefined>("");
    const [localEvento, setLocalEvento] = useState<string | undefined>("");
    const [presencaRepetida, setPresencaRepetida] = useState<string | undefined>("NAO");
    const [contatoOrganizacao, setContatoOrganizacao] = useState<string | undefined>("");
    const [publicoAlvo, setPublicoAlvo] = useState<string[]>([]);
    const [grupoSeguranca, setGrupoSeguranca] = useState<string | undefined>("");
    const [gruposSeguranca, setGruposSeguranca] = useState([]);

    const [evento, setEvento] = useState<IEvento>({});
    const { idEvento } = useParams<{ idEvento: string }>();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        async function listarGruposSeguranca() {
            try {
                const data = await CallbackApiService.listarGruposSeguranca();
                setGruposSeguranca(data);
            } catch (error) {
                setError(error.message);
            }
        }
        listarGruposSeguranca();
    }, []);

    useEffect(() => {
        if (idEvento) {
            getEvento(parseInt(idEvento));
        }
    }, [idEvento]);

    function setError(message: string) {
        dispatch(errorState({ error: message }));
    }
    
    function setLoading(loading: boolean) {
        dispatch(loadingState({ loading: loading }));
    }

    async function getEvento(idEvento: number) {
        try {
            setError("");
            setEvento({});
            setLoading(true);
            const data = await CallbackApiService.listarEventoPorId(idEvento);
            setEvento(data);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            console.log("Falha ao buscar lista de eventos: " + error);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (evento) {
            setId(evento.id);
            setDescricao(evento.descricao);
            setTipoEvento(evento.tipoEvento);
            setTipoParticipacao(evento.tipoParticipacao);
            setTipoRegistroPresenca(evento.tipoRegistroPresenca);
            if (evento.publicoAlvo) {
                setPublicoAlvo(evento.publicoAlvo);
            }
            setPresencaRepetida(evento.presencaRepetida);
            setLocalEvento(evento.localEvento);
            setContatoOrganizacao(evento.contatoOrganizacao);
            setGrupoSeguranca(evento.grupoSeguranca);
        }
    }, [evento]);

    const onSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === true) {
            setValidated(true);
            salvar();
        }
    }

    async function salvar() {
        try {
            setLoading(true);
            setError("");
            let requestData = {} as IEvento;
            requestData.id = id;
            requestData.descricao = descricao;
            requestData.tipoEvento = tipoEvento;
            requestData.tipoParticipacao = tipoParticipacao;
            requestData.tipoRegistroPresenca = tipoRegistroPresenca;
            requestData.publicoAlvo = publicoAlvo;
            requestData.presencaRepetida = presencaRepetida;
            requestData.localEvento = localEvento;
            requestData.contatoOrganizacao = contatoOrganizacao;
            requestData.grupoSeguranca = grupoSeguranca;
            const data = await CallbackApiService.salvarEvento(requestData);
            navigate("/configuracoes/cadastro-evento");
        } catch (error) {
            console.log("Error:" + error);
            setError(error.message);
        }
        setLoading(false);
    }


    function onHidePainel() {
        navigate("/configuracoes/cadastro-evento");
    }


    return (
        <section className="painel-form-evento">
            <div className="titulo-painel ">
                <h2>Cadastro Evento</h2>
            </div>
            <div className="col-md-10 col-sm-12">
                <div className="box-painel">
                    <Form validated={validated} onSubmit={onSubmit}>
                        <div className="row" >
                            <div className="col-md-2 col-sm-12">
                                <Form.Group className="mb-3">
                                    <Form.Label>Id</Form.Label>
                                    <Form.Control readOnly type="text" value={id} />
                                </Form.Group>
                            </div>
                            <div className="col-md-10 col-sm-12">
                                <Form.Group className="mb-3">
                                    <Form.Label>Descrição do Evento</Form.Label><span className="text-danger font-bold">*</span>
                                    <Form.Control type="text" required onChange={(event) => setDescricao(event.target.value)} value={descricao} />
                                    <Form.Control.Feedback type="invalid">
                                        Por Favor Informe a Descrição do Evento.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-md-6 col-sm-12">
                                <Form.Group className="mb-3">
                                    <Form.Label>Tipo de Evento</Form.Label><span className="text-danger font-bold">*</span>
                                    <Form.Select required value={tipoEvento} onChange={(event) => setTipoEvento(event.target.value)} >
                                        <option value="">Selecione Uma Opção</option>
                                        <option value="AGE">Assembleia Geral Extraordinária</option>
                                        <option value="AGO">Assembleia Geral Ordinária</option>
                                        <option value="CAPACITACAO">Capacitação</option>
                                        <option value="CURSO">Curso</option>
                                        <option value="FORMACAO">Formação</option>
                                        <option value="PALESTRA">Palestra</option>
                                        <option value="REUNIAO">Reunião</option>
                                        <option value="TREINAMENTO">Treinamento</option>
                                        <option value="OUTROS">Outros</option>
                                    </Form.Select >
                                    <Form.Control.Feedback type="invalid">
                                        Por Favor Informe o Tipo do Evento.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <Form.Group className="mb-3">
                                    <Form.Label>Público Alvo</Form.Label>
                                    <SelectPublicoAlvo publicoAlvo={publicoAlvo} setPublicoAlvo={setPublicoAlvo} />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-md-6 col-sm-12">
                                <Form.Group className="mb-3">
                                    <Form.Label>Tipo de Participação</Form.Label>
                                    <Form.Select value={tipoParticipacao} onChange={(event) => setTipoParticipacao(event.target.value)} >
                                        <option value="">Selecione Uma Opção</option>
                                        <option value="ABERTO">Aberto(conforme registro de presença)</option>
                                        <option value="FECHADO">Fechado(somente inscritos)</option>
                                    </Form.Select >
                                </Form.Group>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <Form.Group className="mb-3">
                                    <Form.Label>Tipo Registro de Presença</Form.Label>
                                    <Form.Select value={tipoRegistroPresenca} onChange={(event) => setTipoRegistroPresenca(event.target.value)} >
                                        <option value="">Todos</option>
                                        <option value="QRCODECARTAO">Qrcode Cartão Virtual</option>
                                        <option value="QRCODECOLABORADOR">Qrcode Colaborador</option>
                                        <option value="QRCODEINSCRICAO">Qrcode Inscrição</option>
                                    </Form.Select >
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-md-6 col-sm-12">
                                <Form.Label>Presença Repetida</Form.Label>
                                <Form.Select value={presencaRepetida} onChange={(event) => setPresencaRepetida(event.target.value)} >
                                    <option value="SIM">Sim</option>
                                    <option value="NAO">Não</option>
                                </Form.Select >
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <Form.Group className="mb-3">
                                    <Form.Label>Contato Organização Evento</Form.Label><span className="text-danger font-bold">*</span>
                                    <Form.Control type="text" required onChange={(event) => setContatoOrganizacao(event.target.value)} value={contatoOrganizacao} />
                                    <Form.Control.Feedback type="invalid">
                                        Por Favor Informe Dado(s) de Contato para Organização do Evento.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-md-6 col-sm-12">
                                <Form.Group className="mb-3">
                                    <Form.Label>Local do Evento</Form.Label><span className="text-danger font-bold">*</span>
                                    <Form.Control type="text" required onChange={(event) => setLocalEvento(event.target.value)} value={localEvento} />
                                    <Form.Control.Feedback type="invalid">
                                        Por Favor Informe Local do Evento.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <Form.Label>Grupo Segurança</Form.Label>
                                <Form.Select onChange={(event) => setGrupoSeguranca(event.target.value)} value={grupoSeguranca}>
                                    <option value="">Selecione</option>
                                    {Array.isArray(gruposSeguranca) && gruposSeguranca.map((item, i) => (
                                        <option key={i} value={item}>
                                            {item}
                                        </option>
                                    ))}
                                </Form.Select >
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-md-4 col-sm-6">
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="d-grid gap-1">
                                    <Button type="submit" variant="primary" className="botao-custom-unimed" >Confirmar</Button>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="d-grid gap-1">
                                    <Button variant="primary" className="botao-custom-unimed" onClick={() => onHidePainel()}>Fechar </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div >
        </section >
    );
}

export default PainelFormEvento;