import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import PainelAtivoEnum from '../../enums/PainelAtivoEnum.tsx';
import CallbackApiService from "../../services/CallbackApiService.tsx";
import Utils from '../../componentes/Utils/Utils.tsx';
import IGevPessoa from '../../interfaces/IGevPessoa.tsx';
import IRegistroPresencaRequest from '../../interfaces/IRegistroPresencaRequest.tsx';
import FormFileUpload from '../fileupload/FormFileUpload.tsx';
import { useDispatch } from 'react-redux';
import { errorState } from '../../redux/error/actions.ts';
import { loadingState } from '../../redux/loading/actions.ts';
import { useNavigate, useParams } from 'react-router-dom';

const PainelFormGevPessoa = () => {

    const [id, setId] = useState<number | undefined>("");
    const [cpf, setCpf] = useState<string | undefined>("");
    const [nome, setNome] = useState<string | undefined>("");
    const [email, setEmail] = useState<string | undefined>("");
    const [fone, setFone] = useState<string | undefined>("");
    const [matricula, setMatricula] = useState<string | undefined>("");
    const [arquivoImgPerfilId, setArquivoImgPerfilId] = useState<string | undefined>("");
   // const [dadosPessoa, setDadosPessoa] = useState<IGevPessoa>({});

   const { idPessoa } = useParams<{ idPessoa: string }>();
   
   const dispatch = useDispatch();
   const navigate = useNavigate();

   function setError(message: string) {
        dispatch(errorState({ error: message }));
    }

    function setLoading(loading: boolean) {
        dispatch(loadingState({ loading: loading }));
    }

    useEffect(() => {
        if (idPessoa) {
            getPessoaPorId(parseInt(idPessoa));
        }
    }, []);


    useEffect(() => {
        async function validarCPF(value: string | undefined) {
            if (value && value !== "") {
                let cpfDig = String(value).replace(/[^0-9]+/g, "");
                if (Utils.validarCpf(cpfDig)) {
                    console.log("Cpf Valido....")
                    await buscarPessoaPorCpf(cpfDig)
                }
            }
            //   return Utils.formatarDigitos(cpfDig, "###.###.###-##");
        }
        validarCPF(cpf);
    }, [cpf]);

    async function getPessoaPorId(idPessoa: number) {
        try {
            setError("");
            setLoading(true);
            const data = await CallbackApiService.listarPessoaPorId(idPessoa);
            setId(data.id);
            setCpf(data.cpf);
            setNome(data.nome);
            setEmail(data.email);
            setFone(data.fone);
            setMatricula(data.matricula);
            setArquivoImgPerfilId(data.arquivoImagemId);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            console.log("Falha ao buscar lista de eventos: " + error);
            setLoading(false);
        }
    }

    async function buscarPessoaPorCpf(cpf: string) {
        try {
            setError("");
            setLoading(true);
            const dadosPessoa = await CallbackApiService.buscarPessoaPorCpf(cpf);
            if (dadosPessoa.id && Number(dadosPessoa.id) > 0) {
                setId(dadosPessoa.id);
                if (id && String(id) != String(dadosPessoa.id)) {
                    setError("Cpf ja esta cadastrado, conforme dados na tela")
                }
            }
            if (dadosPessoa && dadosPessoa.nome) {
                setNome(dadosPessoa.nome);
            }
            if (dadosPessoa && dadosPessoa.fone) {
                setFone(dadosPessoa.fone);
            }
            if (dadosPessoa && dadosPessoa.email) {
                setEmail(dadosPessoa.email);
            }
            if (dadosPessoa && dadosPessoa.matricula) {
                setMatricula(String(dadosPessoa.matricula));
            }
        } catch (error) {
            setError(error.message);
            console.log("Falha ao buscar dados de pessos cpf:" + cpf + "  Erro:" + error);
        }
        setLoading(false);
    }

    async function salvar() {
        try {
            if (!cpf || !nome) {
                return;
            }
            setLoading(true);
            let requestData = {} as IGevPessoa;
            // remove caracteres nao numericos do cpf
            let digitosCpf = String(cpf).replace(/[^0-9]+/g, "");
            requestData.id = id;
            requestData.cpf = digitosCpf;
            requestData.nome = nome;
            requestData.email = email;
            requestData.fone = fone;
            requestData.matricula = Number(matricula);
            requestData.arquivoImagemId = Number(arquivoImgPerfilId);
            const data = await CallbackApiService.salvarPessoa(requestData);
            setLoading(false);
            redicionamentoGevPessoa();
        } catch (error) {
            setLoading(false);
            console.log("Error:" + error);
            setError(error.message);
        }

    }

    function redicionamentoGevPessoa() {
        navigate("/configuracoes/cadastro-pessoa");
    }

    return (
        <section className="painel-registro-manual">
            <div className="titulo-painel ">
                <h2>Cadastrar Pessoa</h2>
            </div>
            <div className="col-md-10 col-sm-12">
                <div className="box-painel">
                    <Form>
                        <div className="row" >
                            <div className="col-md-4 col-sm-12">
                                <Form.Group className="mb-3">
                                    <Form.Label>Id</Form.Label>
                                    <Form.Control readOnly type="text" value={id} />
                                </Form.Group>
                            </div>
                            <div className="col-md-8 col-sm-12">
                                <Form.Group className="mb-3">
                                    <Form.Label>CPF</Form.Label><span className="text-danger font-bold">*</span>
                                    <Form.Control type="text" onChange={(event) => setCpf(event.target.value)} value={cpf} />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row" >
                            <Form.Group className="mb-3">
                                <Form.Label>Nome</Form.Label><span className="text-danger font-bold">*</span>
                                <Form.Control type="text" onChange={(event) => setNome(event.target.value)} value={nome} />
                            </Form.Group>
                        </div>
                        <div className="row" >
                            <div className="col-md-8 col-sm-12">
                                <div className="row" >
                                    <Form.Group className="mb-3">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" onChange={(event) => setEmail(event.target.value)} value={email} />
                                    </Form.Group>
                                </div>
                                <div className="row" >
                                    <Form.Group className="mb-3">
                                        <Form.Label>Fone</Form.Label>
                                        <Form.Control type="text" onChange={(event) => setFone(event.target.value)} value={fone} />
                                    </Form.Group>
                                </div>
                                <div className="row" >
                                    <Form.Group className="mb-3">
                                        <Form.Label>Matrícula</Form.Label>
                                        <Form.Control type="text" onChange={(event) => setMatricula(event.target.value)} value={matricula} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <Form.Label>Foto Pessoa</Form.Label>
                                <FormFileUpload setArquivoImgPerfilId={setArquivoImgPerfilId} arquivoImgPerfilId={arquivoImgPerfilId} setLoading={setLoading} />
                            </div>
                        </div>

                        <div className="row" >
                            <div className="col-md-4 col-sm-6">
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="d-grid gap-1">
                                    <Button variant="primary" className="botao-custom-unimed" onClick={() => salvar()}>Confirmar</Button>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="d-grid gap-1">
                                    <Button variant="primary" className="botao-custom-unimed" onClick={() => redicionamentoGevPessoa()}>Fechar </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </section>
    );
}

export default PainelFormGevPessoa;