import React, { ChangeEvent, useEffect, useState } from 'react';
import logoUnimed from '../../assets/images/logo-unimed.png';
import calendario from '../../assets/images/calendario.svg';
import loadingCircle from '../../assets/images/loading.svg';
import telefonista from '../../assets/images/telefonista.svg';
import gps from '../../assets/images/gps.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { ITokenInscricao } from '../../interfaces/ITokenInscricao.tsx';
import CallbackApiService from "../../services/CallbackApiService.tsx";
import { IAutoInscricao } from '../../interfaces/IAutoInscricao.tsx';
import "./PaginaInscricaoEvento.css";
import { ISuaInscricao } from '../../interfaces/ISuaInscricao.tsx';
import { EventoEncerrado } from '../../componentes/EventoEncerrado/EventoEncerrado.tsx';

export function PaginaInscricaoEvento() {

    const [searchParams, setSearchParams] = useSearchParams();
    const [nome, setNome] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [cpf, setCpf] = useState<string>("");
    const [telefone, setTelefone] = useState<string>("");
    const [token, setToken] = useState<string>("");
    const [erros, setErros] = useState<{ cpf?: string; telefone?: string; email?: string; nome?: string }>({
        nome: "",
        email: "",
        cpf: "",
        telefone: "",
    });
    const [evento, setEvento] = useState<ITokenInscricao>();
    const [error, setError] = useState<string>();
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [liberado, setLiberado] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [eventoEncerrado, setEventoEncerrado] = useState<boolean>(false);

    useEffect(() => {
        const token = searchParams.get("token");
        if (token !== null) {
            try {
                const parts = token.split(".");
                const base64Url = parts[1];
                const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
                
                const decodedPayload = decodeURIComponent(
                    atob(base64)
                        .split("")
                        .map((c) => `%${("00" + c.charCodeAt(0).toString(16)).slice(-2)}`)
                        .join("")
                );

                const eventoPayload: ITokenInscricao = JSON.parse(decodedPayload);

                if (validateDate(eventoPayload.evento.abertura)) {
                    setEventoEncerrado(true);
                    setLiberado(false);
                } else {
                    setLiberado(true);
                }

                setEvento(eventoPayload);
                setToken(token);
            } catch (error) {
                setReadOnly(true);
                setError("Token não encontrado ou inválido.");
                setLiberado(false);
            }
        } else {
            setReadOnly(true);
            setLiberado(false);
            setError("Token não encontrado ou inválido.");
        }
    }, [searchParams]);

    function validateDate(dateString: string) : boolean {
        const dateTimeParts = dateString.split(" ");
        const dateParts = dateTimeParts[0].split("/");
        const timeParts = dateTimeParts[1].split(":");
    
        const day = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10) - 1;
        const year = parseInt(dateParts[2], 10);
        const hour = parseInt(timeParts[0], 10);
        const minute = parseInt(timeParts[1], 10);
    
        const parsedDate = new Date(year, month, day, hour, minute);
    
        if (
            parsedDate.getFullYear() !== year ||
            parsedDate.getMonth() !== month ||
            parsedDate.getDate() !== day ||
            parsedDate.getHours() !== hour ||
            parsedDate.getMinutes() !== minute
        ) {
            throw new Error("Formato de data inválido: " + dateString);
        }
    
        const now = new Date();
    
        if (parsedDate < now) {
            return true;
        } else {
            return false;
        }
    }

    function isCpfValido(pcpf: string): boolean {
        if (!pcpf || pcpf.length < 3) {
            return false;
        }
        
        let cpf = pcpf.replace(/\D/g, '').padStart(11, '0');
    
        let d1 = 0, d2 = 0;
        let digito1 = 0, digito2 = 0, resto = 0;
    
        for (let nCount = 1; nCount < cpf.length - 1; nCount++) {
            let digitoCPF = parseInt(cpf.charAt(nCount - 1), 10);
    
            d1 += (11 - nCount) * digitoCPF;
    
            d2 += (12 - nCount) * digitoCPF;
        }
    
        resto = d1 % 11;
    
        digito1 = (resto < 2) ? 0 : 11 - resto;
    
        d2 += 2 * digito1;
    
        resto = d2 % 11;
    
        digito2 = (resto < 2) ? 0 : 11 - resto;
    
        let nDigVerific = cpf.slice(-2);
        
        let nDigResult = String(digito1) + String(digito2);
    
        return nDigVerific === nDigResult;
    }

    async function realizarInscricao(): Promise<void>{
        try {
            const camposObrigatorios = {
                nome,
                email,
                cpf,
                telefone
            };
            
            let errosEncontrados = false;
            let novosErros: Record<string, string> = {};
            
            for (let campo in camposObrigatorios) {
                if (camposObrigatorios[campo] === "") {
                    novosErros[campo] = "Campo obrigatório.";
                    errosEncontrados = true;
                }

                if (erros[campo]) {
                    errosEncontrados = true;
                }
            }

            if (!isCpfValido(cpf)) {
                novosErros["cpf"] = "CPF inválido.";
                errosEncontrados = true;
            }

            setErros(prevErros => ({ ...prevErros, ...novosErros }));
            
            if (errosEncontrados) {
                return;
            }

            setError(undefined);
            setLoading(true);
            setLiberado(false);
            
            const cpfFormatado = cpf.replace(/\D/g, "");
            const telefoneFormatado = telefone.replace(/\D/g, "");

            const eventoPayload: IAutoInscricao = {
                token,
                cpf: parseInt(cpfFormatado),
                nome,
                email,
                fone: telefoneFormatado
            }

            const data : ISuaInscricao = await CallbackApiService.realizarAutoInscricao(eventoPayload);
            navigate("/sua-inscricao", { 
                state : {
                    inscricao: data
                }
            });
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
            setLiberado(true);
        }
    }

    function formatarCPF (cpfDigitado: string): string {
        let cpfSomenteNumeros = cpfDigitado.replace(/\D/g, "");
        if (cpfSomenteNumeros.length > 11) {
            return cpf;
        }
        return cpfSomenteNumeros
            .replace(/(\d{3})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    };

    function formatarTelefone (telefoneDigitado: string): string {
        let telefoneSomenteNumeros = telefoneDigitado.replace(/\D/g, "");
        if (telefoneSomenteNumeros.length > 11) {
            return telefone;
        }

        if (telefoneSomenteNumeros.length <= 10) {
            return telefoneSomenteNumeros
                .replace(/(\d{2})(\d)/, "($1) $2")
                .replace(/(\d{4})(\d{1,4})$/, "$1-$2");
        } else {
            return telefoneSomenteNumeros
                .replace(/(\d{2})(\d)/, "($1) $2")
                .replace(/(\d{5})(\d{1,4})$/, "$1-$2");
        }
    }

    function validarCPF (cpfDigitado: string): boolean {
        const somenteNumeros = cpfDigitado.replace(/\D/g, "");
        return somenteNumeros.length === 11;
    };

    function validarTelefone (telefoneDigitado: string): boolean {
        const somenteNumeros = telefoneDigitado.replace(/\D/g, "");
        return somenteNumeros.length === 10 || somenteNumeros.length === 11;
    };

    function validarEmail (emailDigitado: string): boolean {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(emailDigitado);
    }

    function handleBlur (campo: "cpf" | "telefone" | "email" | "nome", valor: string): void {
        if (!readOnly) {
            
            const novoErros = { ...erros };
            
            if (!valor.trim()) {
                    novoErros[campo] = "Campo Obrigatório."
                } else { 
                    if (campo === "cpf" && !validarCPF(valor)) {
                        novoErros.cpf = "CPF inválido.";
                    } else if (campo === "telefone" && !validarTelefone(valor)) {
                        novoErros.telefone = "Telefone inválido.";
                } else if (campo === "email" && !validarEmail(valor)) {
                    novoErros.email = "Email inválido.";
                } else {
                    delete novoErros[campo];
                }
            }
        
            setErros(novoErros);
        }
    };

    return (
        <div className="inscricao">
            <img src={logoUnimed} className="logo-unimed" />
            {
                eventoEncerrado 
                    ?   <EventoEncerrado nomeEvento={evento?.evento.descricaoEvento} nomeAtividade={evento?.evento.descricaoAtividade} />
                    :   <div className="div-inscricao">
                            <h2>Realize sua inscrição</h2>
                            {
                                token !== "" && (
                                    <div className="sobre-evento">
                                        <p className="descricao-evento">{evento?.evento.descricaoEvento}{evento?.evento.programacao && ` - ${evento.evento.descricaoAtividade}`}</p>
                                        <p className="organizacao"><img src={telefonista} alt="telefonista" />{evento?.evento.contatoOrganizacao}</p>
                                        <span className="data-evento">
                                            <img src={calendario} alt="calendario" />
                                            <p>{evento?.evento.abertura} até {evento?.evento.encerramento}</p>
                                            {eventoEncerrado && <p style={{ color: 'red' }}> - encerrado.</p>}
                                        </span>
                                        <p className="local"><img src={gps} alt="gps" />{evento?.evento.localEvento}</p>
                                    </div>
                                )
                            }
                            <div className="div-dados-inscricao">
                                <div className="form-item">
                                    <label className="info-inscricao" htmlFor="nome">Nome</label>
                                    <input 
                                        readOnly={readOnly}
                                        className="dado-inscricao" type="text" name="nome" value={nome}
                                        onChange={(e : ChangeEvent<HTMLInputElement>) => setNome(e.target.value)}
                                        onBlur={() => handleBlur("nome", nome)} />
                                        <span className="error-span">{erros.nome}</span>
                                </div>
                                <div className="form-item">
                                    <label className="info-inscricao" htmlFor="email">Email</label>
                                    <input 
                                        readOnly={readOnly}
                                        className="dado-inscricao" type="email" name="email" value={email}
                                        onChange={(e : ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                                        onBlur={() => handleBlur("email", email)} />
                                    <span className="error-span">{erros.email}</span>
                                </div>
                                <div className="form-item">
                                    <label className="info-inscricao" htmlFor="cpf">CPF</label>
                                    <input 
                                        readOnly={readOnly}
                                        className="dado-inscricao" type="text" name="cpf" value={cpf}
                                        onChange={(e : ChangeEvent<HTMLInputElement>) => setCpf(formatarCPF(e.target.value))}
                                        onBlur={() => handleBlur("cpf", cpf)} />
                                    <span className="error-span">{erros.cpf}</span>
                                </div>
                                <div className="form-item">
                                    <label className="info-inscricao" htmlFor="telefone">Telefone</label>
                                    <input 
                                        readOnly={readOnly}
                                        className="dado-inscricao" type="text" name="telefone" value={telefone}
                                        onChange={(e : ChangeEvent<HTMLInputElement>) => setTelefone(formatarTelefone(e.target.value))} 
                                        onBlur={() => handleBlur("telefone", telefone)}/>
                                    <span className="error-span">{erros.telefone}</span>
                                </div>
                                <Button variant="primary" className="botao-unimed-inscricao"
                                    disabled={!liberado}
                                    onClick={() => realizarInscricao()}>
                                        Inscrever {loading && <img src={loadingCircle} alt='circulo' className="icone" />}
                                </Button>
                            </div>
                        </div>
            }
            <span className="error-inscricao">{error}</span>
        </div>
    )
}