import React, { useEffect, useState, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import IPaginaProgramacao from '../../interfaces/IPaginaProgramacao.tsx';
import IFiltroProgramacao from '../../interfaces/IFiltroProgramacao.tsx';
import DateUtils from '../../componentes/Utils/DateUtils.tsx';
import CallbackApiService from "../../services/CallbackApiService.tsx";
import Pagination from "../../componentes/Pagination/Pagination.js";
import deleteSvg from '../../assets/images/delete.svg';
import editSvg from '../../assets/images/edit.svg';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { errorState } from '../../redux/error/actions.ts';
import { loadingState } from '../../redux/loading/actions.ts';

const DTF: string = 'YYYY-MM-DD HH:mm';
const DTFBACK: string = 'YYYY-MM-DD HH:mm';
const DTFTELA: string = 'DD/MM/YYYY HH:mm'
const PainelCrudProgramacao = () => {


    const LIMIT = 5;
    const [pagina, setPagina] = useState(1);
    const [resultApi, setResultApi] = useState<IPaginaProgramacao>({});
    const [ordem, setOrdem] = useState<string | undefined>("dataInicio");
    const [idEvento, setIdEvento] = useState<number | undefined>();
    const [descricaoEvento, setDescricaoEvento] = useState<string>("");
    const [searchParams, setSearchParams] = useSearchParams();
    
    const { idEventoParam } = useParams<{ idEventoParam: string }>();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const URL_CRUD_EVENTO = "/configuracoes/cadastro-evento";

    useEffect(() => {
        const descricao = searchParams.get("descricao");
        if (descricao !== null) {
            setDescricaoEvento(descricao);
        }
    }, [searchParams]);

    useEffect(() => {
        listar();
    }, []);

    useEffect(() => {
        atualizarListaProgramacao();
    }, [pagina]);

    function setError(message: string) {
        dispatch(errorState({ error: message }));
    }
    
    function setLoading(loading: boolean) {
        dispatch(loadingState({ loading: loading }));
    }

    async function atualizarListaProgramacao() {
        try {
            setError("");
            setResultApi({});
            setLoading(true);
            const data = await CallbackApiService.listarProgramacao(getFiltro());
            setResultApi(data);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            console.log("Falha ao buscar lista de eventos: " + error);
            setLoading(false);
        }
    }

    function listar() {
        if (pagina !== 1) {
            setPagina(1); //ao alterar a pagina já executa a busca
        } else {
            atualizarListaProgramacao();
        }
    }

    function getFiltro() {
        let filtro = {} as IFiltroProgramacao;
        filtro.pagina = pagina;
        filtro.qtdeRegistrosPagina = LIMIT;
        if (idEventoParam) {
            filtro.eventoId = parseInt(idEventoParam);
            setIdEvento(parseInt(idEventoParam));
        }
        filtro.ordem = ordem;
        return filtro;
    }

    async function onDeleteProgramacao(item) {
        try {
            setError("");
            let data = await CallbackApiService.deletarProgramacao(item);
            listar();
        } catch (error) {
            console.log("Error:" + error);
            setError(error.message);
        }      
    }

    const LinhasItemProgramacao = ({ programacao }) => (
        <>
            {Array.isArray(programacao) && programacao.map((item, i) => (
                <tr key={i}>
                    <td className="text-start">
                        {item.atividade}
                    </td>
                    <td className="text-start">
                        {DateUtils.formatarData(DateUtils.getDate(item.dataAbertura, DTFBACK), DTFTELA)}
                    </td>
                    <td className="text-start">
                        {DateUtils.formatarData(DateUtils.getDate(item.dataInicio, DTFBACK), DTFTELA)}
                    </td>
                    <td className="text-start">
                        {DateUtils.formatarData(DateUtils.getDate(item.dataFim, DTFBACK), DTFTELA)}
                    </td>
                    <td className="text-center">
                        <img src={editSvg} className="img-icon-svg" onClick={() => navigate(`${URL_CRUD_EVENTO}/programacao/form/${item.id}?idEvento=${idEvento}&descricao=${descricaoEvento}`)} />
                    </td>
                    <td className="text-center">
                        <img src={deleteSvg} className="img-icon-svg" onClick={() => onDeleteProgramacao(item)} />
                    </td>
                </tr>
            ))}
        </>
    );


    const ListProgramacao = ({ programacao }) => (

        <div className="table-responsive">
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th colSpan={6} className="text-center">Atividades Programadas para o Evento</th>    
                    </tr>
                </thead>
                <thead >
                    <tr key={0} className="green-text" >
                        <th className="text-start">Atividade</th>
                        <th className="text-start">Entrada Liberada</th>
                        <th className="text-start">Data Hora Início</th>
                        <th className="text-start">Data Hora Encerramento</th>
                        <th className="text-center">Alterar</th>
                        <th className="text-center">Deletar</th>
                    </tr>
                </thead>
                <tbody>
                    {programacao && <LinhasItemProgramacao programacao={programacao} />}
                </tbody>
            </table>
        </div>
    );

    return (
        <section className="painel-eventos">
            <div className="titulo-painel ">
                <h2>Programar Atividades Evento {descricaoEvento}</h2>
            </div>
            <div className="col-md-10 col-sm-12">
                <div className="box-painel">
                    <Form>

                        <div className="row" >
                            <div className="col-md-6 col-sm-6"></div>
                            <div className="col-md-3 col-sm-6">
                                <div className="d-grid gap-1">
                                    <Button variant="primary" className="botao-custom-unimed" onClick={() => navigate(`${URL_CRUD_EVENTO}/programacao/form?idEvento=${idEvento}&descricao=${descricaoEvento}`)}>Nova Atividade </Button>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="d-grid gap-1">
                                    <Button variant="primary" className="botao-custom-unimed" onClick={() => navigate(URL_CRUD_EVENTO)}>Fechar </Button>
                                </div>
                            </div>
                        </div>
                        <div className="row"><br /><br /></div>
                    </Form>
                    <div className="row">
                        {resultApi && <ListProgramacao programacao={resultApi.programacaoList} />}
                        <div className="row">
                            {resultApi.programacaoList && <div className="col d-flex align-items-center">Total de Registros: {resultApi.qtdeRegistrosTotais} </div>}
                            {resultApi.programacaoList && <Pagination limit={LIMIT} total={resultApi.qtdeRegistrosTotais} pagina={pagina} setPagina={setPagina} />}
                        </div>
                        <div className="row"><br /><br /></div>
                    </div>
                </div>
            </div >
        </section >
    );

}

export default PainelCrudProgramacao;