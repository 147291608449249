import React from 'react';
import "./PaginaGestaoEventos.css";
import LoadingWait from "../../componentes/Loading/Loading.js";
import logoUnimed from '../../assets/images/logo-unimed.png';
import settingsSvg from '../../assets/images/settings.svg';
import formSvg from '../../assets/images/form.svg';
import scanSvg from '../../assets/images/scan-qrcode.svg';
import userCheckSvg from '../../assets/images/user-check.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { errorState } from '../../redux/error/actions.ts';

const PaginaGestaoEventos = () => {

  const { error } = useSelector((state: any) => state.errorReducer);
  const { loading } = useSelector((state: any) => state.loadingReducer);
  
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  function setError(message: string) {
    dispatch(errorState({ error: message }));
  }
  
  function onHideViewConfiguracoes() {
    setError("");
    if (location.pathname !== "/configuracoes") {
      navigate("/configuracoes");
    } else {
      navigate("/");
    }
  }

  function onStartStopScanner() {
    setError("");
    if (location.pathname !== "/registro-presenca-qr") {
      navigate("/registro-presenca-qr");
    } else {
      navigate("/");
    }
  }

  function onHideViewListaDePresenca() {
    setError("");
    if (location.pathname !== "/lista-presenca") {
      navigate("/lista-presenca");
    } else {
      navigate("/");
    }
  }

  function onHideViewRegistroManual() {
    setError("");
    if (location.pathname !== "/registro-presenca-manual") {
      navigate("/registro-presenca-manual");
    } else {
      navigate("/");
    }
  }

  return (
    <div>
      <div className="div-barra-horizontal">
        <div className="div-box-item-menu">
          <div className="div-logo-unimed">
            <img src={logoUnimed} className="img-logo-unimed" onClick={() => navigate("/")} style={{ cursor: "pointer" }} />
          </div>
        </div>
        <div className="div-box-item-menu">
          <div className="div-box-menu" onClick={() => onHideViewConfiguracoes()} >
            <img src={settingsSvg} className="img-box-menu-svg" />
            <span className="text-box-menu">
              Configurações
            </span>
          </div>
        </div>
        <div className="div-box-item-menu">
          <div className="div-box-menu" onClick={() => onStartStopScanner()} >
            <img src={scanSvg} className="img-box-menu-svg" />
            <span className="text-box-menu">
              {location.pathname === "/registro-presenca-qr" ? "Parar" : "Iniciar"} Leitor QrCode
            </span>
          </div>
        </div>
        <div className="div-box-item-menu">
          <div className="div-box-menu" onClick={() => onHideViewListaDePresenca()} >
            <img src={userCheckSvg} className="img-box-menu-svg" />
            <span className="text-box-menu">
              Lista de Presença
            </span>
          </div>
        </div>

        <div className="div-box-item-menu">
          <div className="div-box-menu" onClick={() => onHideViewRegistroManual()} >
            <img src={formSvg} className="img-box-menu-svg" />
            <span className="text-box-menu">
              Registrar Presença
            </span>
          </div>
        </div>

      </div>
      {location.pathname !== "/" && <Outlet />}

      {loading && <LoadingWait />}
      {error && <div className='row'><span className="alert-error"> {error} </span></div>}
    </div>
  );
}
export default PaginaGestaoEventos;