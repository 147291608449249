import { combineReducers } from "redux";
import monitoramentoReducer from "./monitoramento/reducer.ts";
import programacaoReducer from "./programacao/reducer.ts";
import eventoReducer from "./evento/reducer.ts";
import errorReducer from "./error/reducer.ts";
import loadingReducer from "./loading/reducer.ts";
import registroPresencaReducer from "./registro-presenca/reducer.ts";

const rootReducer = combineReducers({ 
    monitoramentoReducer, 
    programacaoReducer,
    eventoReducer,
    errorReducer,
    loadingReducer,
    registroPresencaReducer,
});

export default rootReducer;