import React, { ChangeEvent, useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import CallbackApiService from "../../services/CallbackApiService.tsx";
import DateUtils from '../../componentes/Utils/DateUtils.tsx';
import IFiltroProgramacao from '../../interfaces/IFiltroProgramacao.tsx';
import IProgramacaoDto from '../../interfaces/IProgramacaoDto.tsx';
import RenderOnRole from "../../componentes/RenderOnRole.jsx";
import { useDispatch, useSelector } from 'react-redux';
import { monitoramentoState } from '../../redux/monitoramento/actions.ts';
import { programacaoState } from '../../redux/programacao/actions.ts';
import { useNavigate } from 'react-router-dom';
import { errorState } from '../../redux/error/actions.ts';

const DF: string = 'DD-MM-YYYY HH:mm';
const DTF: string = 'YYYY-MM-DD HH:mm';
const DTFBACK: string = 'YYYYMMDD HHmm';
const PainelConfiguracoes = () => {

    const [programacoes, setProgramacoes] = useState<IProgramacaoDto[]>([]);
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function setError(message: string) {
        dispatch(errorState({ error: message }));
    }

    const { monitoramento } = useSelector((state : any) => state.monitoramentoReducer);
    const { programacao } = useSelector((state: any) => state.programacaoReducer);
  
    function handleOperadorChange(event: ChangeEvent<HTMLSelectElement>) {
        dispatch(monitoramentoState({ monitoramento: event.target.value }));
    }
    
    
    function setProgramacao(programa: IProgramacaoDto) {
        dispatch(programacaoState({ programacao: programa }));
    }

    useEffect(() => {
        async function listarEventosAtivos() {
            try {
                const data = await CallbackApiService.listarProgramacoes(getFiltro());
                setProgramacoes(data);
                if (Array.isArray(programacoes) && programacoes.length == 1) {
                    setProgramacao(programacoes[0]);
                }
            } catch (error) {
                setError(error.message);
            }
        }
        listarEventosAtivos();
    }, []);

    function onPainelCrudPessoa() {
        navigate("/configuracoes/cadastro-pessoa");
    }

    const onChangeProgramacao = (e) => {
        let itemSelecionado = e.target.value;
        let lg = programacoes.length;
        setProgramacao({});
        for (let i = 0; i < lg; i++) {
            if (String(programacoes[i].programacaoId) === String(itemSelecionado)) {
                setProgramacao(programacoes[i]);
                break;
            }
        }
    }

    function getFiltro() {
        let filtro = {} as IFiltroProgramacao;
        let data = new Date();
        filtro.dataOcorrencia = DateUtils.formatarData(data, DTFBACK).replace(" ", "");
        filtro.ordem = "data_abertura";
        return filtro;
    }


    return (
        <section className="painel-configuracoes">
            <div className="titulo-painel">
                <h2>Configurações</h2>
            </div>
            <div className="col-md-8 col-sm-12">
                <div className="box-painel">
                    <div className="row" >
                        <Form.Group className="mb-3">
                            <Form.Label>Evento Ativo</Form.Label>
                            <div className="d-flex">
                                <Form.Select onChange={onChangeProgramacao} value={programacao.programacaoId}>
                                    <option value="">Selecione uma das atividades em andamento</option>
                                    {Array.isArray(programacoes) && programacoes.map((item, i) => (
                                        <option key={i} value={item.programacaoId}>
                                            {item.descricaoEvento + " - " + item.atividade + " -> Programação: " + DateUtils.formatarData(DateUtils.getDate(item.abertura, DTF), DF) + " a " + DateUtils.formatarData(DateUtils.getDate(item.encerramento, DTF), DF)}
                                        </option>
                                    ))}
                                </Form.Select >
                            </div>

                        </Form.Group>
                    </div>
                    <div className="row" >
                        <Form.Group className="mb-3">
                            <Form.Label>Tipo Monitoramento</Form.Label>
                            <Form.Select value={monitoramento} onChange={(event) => handleOperadorChange(event)} >
                                <option value="OPERADOR">Operador</option>
                                <option value="AUTOMATICO">Automático</option>
                            </Form.Select >
                        </Form.Group>
                    </div>
                    <div className="row" >
                        <div className="col-sm-4">
                            <div className="d-grid gap-1">
                                <label>&nbsp;</label>
                                <RenderOnRole roles={['admin-evento', 'admin-evento']} showNotAllowed={false}>
                                    <Button variant="primary" className="botao-custom-unimed" onClick={() => navigate("/configuracoes/cadastro-evento")}>Cadastro Eventos </Button>
                                </RenderOnRole>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="d-grid gap-1">
                                <label>&nbsp;</label>
                                <RenderOnRole roles={['admin-evento', 'admin-evento']} showNotAllowed={false}>
                                    <Button variant="primary" className="botao-custom-unimed" onClick={() => onPainelCrudPessoa()}>Cadastro Pessoas </Button>
                                </RenderOnRole>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="d-grid gap-1">
                                <label>&nbsp;</label>
                                <Button variant="primary" className="botao-custom-unimed" onClick={() => navigate("/")}>OK </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PainelConfiguracoes;