import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import "./PainelInscricoes.css";
import IPaginaInscricao from '../../interfaces/IPaginaInscricao.tsx';
import IFiltroInscricao from '../../interfaces/IFiltroInscricao.tsx';
import CallbackApiService from "../../services/CallbackApiService.tsx";
import Pagination from "../../componentes/Pagination/Pagination.js";
import deleteSvg from '../../assets/images/delete.svg';
import IGevPessoa from '../../interfaces/IGevPessoa.tsx';
import Utils from '../../componentes/Utils/Utils.tsx';
import IInscricaoRequest from '../../interfaces/IInscricaoRequest.tsx';
import DateUtils from '../../componentes/Utils/DateUtils.tsx';
import { CopyLink } from '../../componentes/CopyLink/CopyLink.tsx';
import IProgramacaoDto from '../../interfaces/IProgramacaoDto.tsx';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { errorState } from '../../redux/error/actions.ts';
import { loadingState } from '../../redux/loading/actions.ts';

const DF: string = 'DD-MM-YYYY HH:mm';
const DTF: string = 'YYYY-MM-DD HH:mm';
const PainelInscricoes = () => {

    const LIMIT = 10;
    const [pagina, setPagina] = useState(1);
    const [idEvento, setIdEvento] = useState<number | undefined>();
    const [resultApi, setResultApi] = useState<IPaginaInscricao>({});
    const [ordem, setOrdem] = useState<string | undefined>("NOME");
    const [programacaoId, setProgramacaoId] = useState<string | undefined>("");
    const [programacoes, setProgramacoes] = useState<IProgramacaoDto[]>([]);
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [cpf, setCpf] = useState<string | undefined>("");
    const [nome, setNome] = useState<string | undefined>("");
    const [email, setEmail] = useState<string | undefined>("");
    const [fone, setFone] = useState<string | undefined>("");
    const [dadosPessoa, setDadosPessoa] = useState<IGevPessoa>({});
    const [pessoaOk, setPessoaOk] = useState<boolean>(false);
    const [descricaoEvento, setDescricaoEvento] = useState<string>("");

    const [searchParams, setSearchParams] = useSearchParams();
    const { idEventoParam } = useParams<{ idEventoParam: string }>();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        listarProgramacoes();
        listar();
    }, []);

    useEffect(() => {
        atualizarListaInscricoes();
    }, [pagina]);

    useEffect(() => {
        const descricao = searchParams.get("descricao");
        if (descricao !== null) {
            setDescricaoEvento(descricao);
        }
    }, [searchParams]);

    function setError(message: string) {
        dispatch(errorState({ error: message }));
    }
    
    function setLoading(loading: boolean) {
        dispatch(loadingState({ loading: loading }));
    }

    async function atualizarListaInscricoes() {
        try {
            setError("");
            setResultApi({});
            setLoading(true);
            const data = await CallbackApiService.listarInscricoes(getFiltro());
            setResultApi(data);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    }

    function listar() {
        if (pagina !== 1) {
            setPagina(1); //ao alterar a pagina já executa a busca
        } else {
            atualizarListaInscricoes();
        }
    }

    function getFiltroProgramacoes() {
        let filtro = {} as IFiltroInscricao
        if (idEventoParam) {
            filtro.eventoId = parseInt(idEventoParam);
            setIdEvento(parseInt(idEventoParam));
        }
        return filtro;
    }

    function getFiltro() {
        let filtro = {} as IFiltroInscricao
        filtro.pagina = pagina;
        filtro.qtdeRegistrosPagina = LIMIT;
        if (idEventoParam) {
            filtro.eventoId = parseInt(idEventoParam);
            setIdEvento(parseInt(idEventoParam));
        }
        filtro.ordem = ordem;
        return filtro;
    }

    useEffect(() => {
        return setCpf(formatarValidarCPF(cpf));
    }, [cpf]);

    useEffect(() => {
        function verificarDadosOk() {
            setPessoaOk(false);
            if (cpf && nome) {
                setPessoaOk(true);
            }
        }
        verificarDadosOk();
    }, [cpf, nome]);

    function formatarValidarCPF(value: string | undefined) {
        setDadosPessoa({});
        if (!value)
            return value;
        // remove caracteres nao numericos
        let cpf = String(value.replace(/[^0-9]+/g, ""));
        if (Utils.validarCpf(cpf)) {
            console.log("Cpf Valido....")
            buscarPessoa(cpf)
        }
        return Utils.formatarDigitos(cpf, "###.###.###-##");
    }

    async function listarProgramacoes() {
        try {
            const data = await CallbackApiService.listarProgramacoes(getFiltroProgramacoes());
            setProgramacoes(data);
        } catch (error) {
            setError(error.message);
        }
    }

    async function buscarPessoa(cpf: string) {
        try {
            setError("");
            setDadosPessoa({});
            setLoading(true);
            const data = await CallbackApiService.buscarPessoaPorCpf(cpf);
            setDadosPessoa(data);
            if (data.nome) {
                setNome(data.nome);
            }
            if (data.fone) {
                setFone(data.fone);
            }
            if (data.email) {
                setEmail(data.email);
            }
        } catch (error) {
            console.log("Falha ao buscar dados de pessos cpf:" + cpf + "  Erro:" + error);
        }
        setLoading(false);
    }


    async function adicionarInscricao() {
        try {
            if (!cpf || !dadosPessoa || !dadosPessoa.nome) {
                return;
            }
            setLoading(true);
            let digitosCpf = String(cpf.replace(/[^0-9]+/g, ""));

            let inscricaoRequest = {} as IInscricaoRequest;
            inscricaoRequest.eventoId = idEvento;
            inscricaoRequest.programacaoId = programacaoId;
            inscricaoRequest.pessoaId = dadosPessoa.id;
            inscricaoRequest.cpf = Number(digitosCpf);
            inscricaoRequest.nome = nome;
            inscricaoRequest.email = email;
            inscricaoRequest.fone = fone;
            const data = await CallbackApiService.gerarInscricao(inscricaoRequest);
            setCpf("");
            setNome("");
            setFone("");
            setEmail("");
            setDadosPessoa({});
            setLoading(false);
            listar()
        } catch (error) {
            setLoading(false);
            console.log("Error:" + error);
            setError(error.message);
        }
    }

    const onClickUploadPlanilhaInscricoes = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        uploadPlanilhaInscricoes();
    }

    async function uploadPlanilhaInscricoes() {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append("file", file);
            let data = await CallbackApiService.uploadPlanilhaInscricao(idEvento, programacaoId, formData);
            setTimeout(function () { listar() }, 3000);
        } catch (error) {
            setError(error.message);
        }
        setLoading(false);
    }

    async function exportarInscricoesToXlsx() {
        try {
            setError("");
            setLoading(true);
            const arquivoRetorno = await CallbackApiService.exportarInscricoes(getFiltro());
            setLoading(false);
            downLoadFile(arquivoRetorno.conteudoBase64, arquivoRetorno.nome, "dowload");
        } catch (error) {
            setError(error.message);
            console.log("Falha ao exportar Inscricoes: " + error);
            setLoading(false);
        }
    }

    async function onDowloadPlanilhaModelo() {
        try {
            setError("");
            setLoading(true);
            const data = await CallbackApiService.buscarPlanilhaInscricoesExemplo();
            downLoadFile(data.conteudoBase64, data.nome, "download");
            setLoading(false);
        } catch (error) {
            setError(error.message);
            console.log("Falha ao buscar planilha modelo para importacao de Inscrições: " + error);
            setLoading(false);
        }
    }


    function downLoadFile(conteudoBase64, nameArq, opcao) {
        const bufferArray = base64ToArrayBuffer(conteudoBase64);
        let blob = new Blob([bufferArray], { type: 'application/octet-stream' });
        let url = window.URL.createObjectURL(blob);
        if (opcao == "openNewTab") {
            var newWindow = window.open(url, '_blank');
            setTimeout(function () { newWindow.document.title = nameArq; }, 3000);
        } else {
            let a = document.createElement('a');
            a.href = url;
            a.download = nameArq;
            a.click();
        }
    }

    function base64ToArrayBuffer(conteudoBase64) {
        const bString = window.atob(conteudoBase64);
        const bLength = bString.length;
        const bytes = new Uint8Array(bLength);
        for (let i = 0; i < bLength; i++) {
            bytes[i] = bString.charCodeAt(i);
        }
        return bytes;
    }

    async function onDeleteInscricao(inscricao) {
        await CallbackApiService.deletarInscricao(inscricao);
        listar();
    }

    async function onEviarInscricao() {
        setLoading(true);
        let filtro = {} as IFiltroInscricao
        filtro.eventoId = idEvento;
        await CallbackApiService.enviarInscricaoParaParticipante(filtro);
        setLoading(false);
        listar();

    }

    const LinhasItemInscricao = ({ inscricoes }) => (
        <>
            {Array.isArray(inscricoes) && inscricoes.map((item, i) => (
                <tr key={i}>
                    <td className="text-start">
                        {item.nome}
                    </td>
                    <td className="text-start">
                        {item.cpf}
                    </td>
                    <td className="text-start">
                        {item.fone}
                    </td>
                    <td className="text-start">
                        {item.email}
                    </td>
                    <td className="text-center">
                        <img src={deleteSvg} className="img-icon-svg" onClick={() => onDeleteInscricao(item)} />
                    </td>
                </tr>
            ))}
        </>
    );


    const ListInscricoes = ({ inscricoes }) => (

        <div className="table-responsive">
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th colSpan={6} className="text-center">Inscritos para o Evento</th>
                    </tr>
                </thead>
                <thead>
                    <tr key={0} className="green-text" >
                        <th className="text-start">nome</th>
                        <th className="text-start">cpf</th>
                        <th className="text-start">fone</th>
                        <th className="text-start">email</th>
                        <th className="text-center">Deletar</th>
                    </tr>
                </thead>
                <tbody>
                    {inscricoes && <LinhasItemInscricao inscricoes={inscricoes} />}
                </tbody>
            </table>
        </div>
    );

    return (
        <section className="painel-eventos">
            <div className="titulo-painel ">
                <h2>Cadastro Inscrições Evento {descricaoEvento}</h2>
            </div>
            <div className="col-md-10 col-sm-12">
                <div className="box-painel">
                    <div className="row" >
                        <Form.Group className="mb-3">
                            <Form.Label>Atividade</Form.Label>
                            <div className="d-flex">
                                <Form.Select onChange={(event) => setProgramacaoId(event.target.value)} value={programacaoId}>
                                    <option value="">Todas</option>
                                    {Array.isArray(programacoes) && programacoes.map((item, i) => (
                                        <option key={i} value={item.programacaoId}>
                                            {item.atividade + " -> De: " + DateUtils.formatarData(DateUtils.getDate(item.abertura, DTF), DF) + " a " + DateUtils.formatarData(DateUtils.getDate(item.encerramento, DTF), DF)}
                                        </option>
                                    ))}
                                </Form.Select >
                                {idEvento && <CopyLink eventoId={idEvento} programacaoId={programacaoId} />}
                            </div>
                        </Form.Group>
                    </div>
                    <div className="row" >
                        <div className="formularioImportar">
                            <form>
                                <input
                                    type="file"
                                    accept=".xlsx, application/vnd.ms-excel"
                                    onChange={(e) => setFile(e.target.files[0])} />
                                <button type="submit" disabled={uploading || !file} className="botao-custom-unimed" onClick={onClickUploadPlanilhaInscricoes}>Importar Planilha </button>
                                <span className="label-link-planilha-modelo" onClick={() => onDowloadPlanilhaModelo()}>
                                    Planilha Modelo
                                </span>
                            </form>
                        </div>
                    </div>
                    <Form>

                        <div className="row" >
                            <div className="col-md-4 col-sm-12">
                                <Form.Group className="mb-3">
                                    <Form.Label>CPF</Form.Label>
                                    <Form.Control type="text" onChange={(event) => setCpf(event.target.value)} value={cpf} />
                                </Form.Group>
                            </div>
                            <div className="col-md-8 col-sm-12">
                                <Form.Group className="mb-3">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control type="text" onChange={(event) => setNome(event.target.value)} value={nome} />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-md-4 col-sm-12">
                                <Form.Group className="mb-3">
                                    <Form.Label>Fone</Form.Label>
                                    <Form.Control type="text" onChange={(event) => setFone(event.target.value)} value={fone} />
                                </Form.Group>
                            </div>
                            <div className="col-md-5 col-sm-12">
                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" onChange={(event) => setEmail(event.target.value)} value={email} />
                                </Form.Group>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="d-grid gap-1">
                                    <label>&nbsp;</label>
                                    <Button disabled={!pessoaOk} variant="primary" className="botao-custom-unimed" onClick={() => adicionarInscricao()}>Add Inscrição</Button>
                                </div>
                            </div>
                        </div>
                        <div className="row"><br /></div>
                        <div className="row" >
                            <div className="col-md-3 col-sm-6">
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="d-grid gap-1">
                                    <Button variant="primary" className="botao-custom-unimed" onClick={() => exportarInscricoesToXlsx()}>Exportar xlsx </Button>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="d-grid gap-1">
                                    <Button variant="primary" className="botao-custom-unimed" onClick={() => onEviarInscricao()}>Enviar Credenciais </Button>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="d-grid gap-1">
                                    <Button variant="primary" className="botao-custom-unimed" onClick={() => navigate("/configuracoes/cadastro-evento")}>Fechar </Button>
                                </div>
                            </div>
                        </div>
                        <div className="row"><br /></div>
                    </Form>
                    <div className="row">
                        {resultApi && <ListInscricoes inscricoes={resultApi.inscricoes} />}
                        <div className="row">
                            {resultApi.inscricoes && <div className="col d-flex align-items-center">Total de inscrições: {resultApi.qtdeRegistrosTotais} </div>}
                            {resultApi.inscricoes && <Pagination limit={LIMIT} total={resultApi.qtdeRegistrosTotais} pagina={pagina} setPagina={setPagina} />}
                        </div>
                        <div className="row"><br /><br /></div>
                    </div>
                </div>
            </div >
        </section >
    );
}

export default PainelInscricoes;