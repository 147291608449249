import React, { useState, useRef, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import "./SelectPessoaFilter.css";
import IPessoaDto from '../../interfaces/IPessoaDto.tsx';
import CallbackApiService from '../../services/CallbackApiService.tsx';


const SelectPessoaFilter = ({ selectedPessoa, setSelectedPessoa, setLoading, setError }) => {
  const [pessoas, setPessoas] = useState<IPessoaDto[]>([]);
  const [dropdownSearchValue, setDropdownSearchValue] = useState("")
  const [editMode, setEditMode] = useState(false)
  const dropdownRef = useRef()

  const onlyNumbers = new RegExp('^[0-9]+$');

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (
        editMode &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        setEditMode(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [editMode])

  useEffect(() => {
    async function buscarPessoa(termo: string) {
      try {
        setLoading(true);
        const data = await CallbackApiService.listarPessoasDto(termo);
        setPessoas(data);
      } catch (error) {
        setError(error.message);
      }
      setLoading(false);
    }
    setError("");
    setPessoas([]);
    if (dropdownSearchValue.length > 5 || (onlyNumbers.test(dropdownSearchValue) && dropdownSearchValue.length >= 2)) {
      buscarPessoa(dropdownSearchValue);
    }
  }, [dropdownSearchValue]);


  const skillSelectionHandler = pessoa => {
    setSelectedPessoa(pessoa)
    setPessoas([]);
    setDropdownSearchValue("")
    setEditMode(false)
  }


  return (
    <div className="select-data-filter">
      {editMode ? (
        // display the dropdown when the input us focused
        <div ref={dropdownRef} className="dropdown-wrapper">
          <Form.Control type="text"
            autoFocus
            onChange={e => setDropdownSearchValue(e.target.value)}
            value={dropdownSearchValue}
          />
          <div className="dropdown-list">
            <ul>
              {pessoas.map(pessoa => {
                return (
                  <li key={pessoa.cpf} onClick={() => skillSelectionHandler(pessoa)}>
                    {pessoa.nome}{" "}
                  </li>
                )
              })}
              {pessoas.length === 0 && (
                <li className="no-result">Nenhum Registro Encontrado.</li>
              )}
            </ul>
          </div>
        </div>
      ) : (
        <Form.Control type="text"
          onFocus={() => setEditMode(true)}
          placeholder="Informe CPF, Nome ou Matricula"
        />
      )}
    </div>
  )

}

export default SelectPessoaFilter;
