import React, { useState } from "react";
import linkCopySvg from '../../assets/images/link-copy.svg';
import CallbackApiService from "../../services/CallbackApiService.tsx";
import { ICopyLink } from "../../interfaces/ICopyLink.tsx";

export function CopyLink ( {eventoId, programacaoId} : ICopyLink ) {

    const [isCopied, setIsCopied] = useState<boolean>(false);

    function fallbackCopyTextToClipboard (text: string) {
        const textArea = document.createElement("textarea");
        textArea.value = text;
      
        textArea.style.position = "fixed";
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.width = "2em";
        textArea.style.height = "2em";
        textArea.style.padding = "0";
        textArea.style.border = "none";
        textArea.style.outline = "none";
        textArea.style.boxShadow = "none";
        textArea.style.background = "transparent";
      
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
      
        try {
          document.execCommand("copy");
          console.log("Texto copiado usando fallback!");
        } catch (err) {
          console.error("Falha ao copiar texto: ", err);
        }
      
        document.body.removeChild(textArea);
    };
    
    async function onLinkCopy() {
        let data = await CallbackApiService.getLinkAutoInscricao(eventoId, programacaoId);

        if (navigator.clipboard) {
            navigator.clipboard.writeText(data.mensagem);
        } else {
            fallbackCopyTextToClipboard(data.mensagem);
        }

        setIsCopied(true);

        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    }

    return (
        <div 
            className="div-box-menu position-relative"
            onClick={onLinkCopy}
            style={{ display: "inline-block" }}>
            <img
                src={linkCopySvg}
                className="img-box-menu-svg"
                alt="Ícone de copiar link"
                title="Clique para copiar o link de inscrição"
            />
            {isCopied && (
                <div
                className="tooltip-box position-absolute bg-success text-white rounded-pill px-2 py-1"
                style={{
                    top: "-50px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    fontSize: "15px",
                    whiteSpace: "nowrap",
                }}
                >
                Link copiado
                <div
                    className="arrow position-absolute bg-success"
                    style={{
                    bottom: "-5px",
                    left: "50%",
                    transform: "translateX(-50%) rotate(45deg)",
                    width: "10px",
                    height: "10px",
                    }}
                />
                </div>
            )}
        </div>
    )
}