import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import IEvento from '../../interfaces/IEvento.tsx';
import IPaginaEvento from '../../interfaces/IPaginaEvento.tsx';
import IFiltroEvento from '../../interfaces/IFiltroEvento.tsx';
import CallbackApiService from "../../services/CallbackApiService.tsx";
import Pagination from "../../componentes/Pagination/Pagination.js";
import deleteSvg from '../../assets/images/delete.svg';
import editSvg from '../../assets/images/edit.svg';
import agendaPencilSvg from '../../assets/images/agenda-pencil.svg';
import PeopleGroupSvg from '../../assets/images/users-people.svg';
import { useDispatch, useSelector } from 'react-redux';
import { eventoState } from '../../redux/evento/actions.ts';
import { useNavigate } from 'react-router-dom';
import { errorState } from '../../redux/error/actions.ts';
import { loadingState } from '../../redux/loading/actions.ts';

const PainelCrudEvento = () => {

    const LIMIT = 10;
    const [pagina, setPagina] = useState(1);
    const [stringPesquisa, setStringPesquisa] = useState("");
    const [ordem, setOrdem] = useState("descricao");
    const [resultApi, setResultApi] = useState<IPaginaEvento>({});

    const { error } = useSelector((state: any) => state.errorReducer);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const URL_PAINEL_FORM_EVENTO = "/configuracoes/cadastro-evento";

    function setEvento(evento: IEvento) {
        dispatch(eventoState({ evento: evento }));
    }

    function setError(message: string) {
        dispatch(errorState({ error: message }));
    }
    
    function setLoading(loading: boolean) {
        dispatch(loadingState({ loading: loading }));
    }

    useEffect(() => {
        atualizarListaEventos();
    }, [pagina]);


    async function atualizarListaEventos() {
        try {
            setError("");
            setResultApi({});
            setLoading(true);
            const data = await CallbackApiService.listarEventos(getFiltro());
            setResultApi(data);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            console.log("Falha ao buscar lista de eventos: " + error);
            setLoading(false);
        }
    }

    function listar() {
        if (pagina !== 1) {
            setPagina(1); //ao alterar a pagina já executa a busca
        } else {
            atualizarListaEventos();
        }
    }

    function getFiltro() {
        let filtro = {} as IFiltroEvento;
        filtro.pagina = pagina;
        filtro.qtdeRegistrosPagina = LIMIT;
        filtro.termo = stringPesquisa;
        filtro.ordem = ordem;
        return filtro;
    }
    
    function onInscricoesEvento(evento) {
        setEvento(evento);
    }

    async function onDeleteEvento(evento) {
        await CallbackApiService.deletarEvento(evento)
        atualizarListaEventos();
    }

    const LinhasEventos = ({ eventos }) => (
        <>
            {Array.isArray(eventos) && eventos.map((item, i) => (
                <tr key={i}>
                    <td className="text-start">
                        {item.descricao}
                    </td>
                    <td className="text-start">
                        {item.tipoEvento}
                    </td>
                    <td className="text-start">
                        {item.tipoParticipacao}
                    </td>
                    <td className="text-start">
                        {item.tipoRegistroPresenca}
                    </td>
                    <td className="text-center">
                        <img src={agendaPencilSvg} className="img-icon-svg" onClick={() => navigate(URL_PAINEL_FORM_EVENTO + `/programacao/${item.id}?descricao=${item.descricao}`)} />
                    </td>
                    <td className="text-center">
                        <img src={PeopleGroupSvg} className="img-icon-svg" onClick={() => navigate(URL_PAINEL_FORM_EVENTO + `/inscricoes/${item.id}?descricao=${item.descricao}`)} />
                    </td>
                    <td className="text-center">
                        <img src={editSvg} className="img-icon-svg" onClick={() => navigate(URL_PAINEL_FORM_EVENTO + `/form/${item.id}`)} />
                    </td>
                    <td className="text-center">
                        <img src={deleteSvg} className="img-icon-svg" onClick={() => onDeleteEvento(item)} />
                    </td>
                </tr>
            ))}
        </>
    );


    const ListEventos = ({ eventos }) => (
        <div className="table-responsive">
            <table className="table table-striped table-hover">
                <thead >
                    <tr key={0} className="green-text" >
                        <th className="text-start">Descrição</th>
                        <th className="text-start">Tipo Evento</th>
                        <th className="text-start">Tipo Participação</th>
                        <th className="text-start">Tipo Registro Presença</th>
                        <th className="text-center">Programação</th>
                        <th className="text-center">Inscrições</th>
                        <th className="text-center">Alterar</th>
                        <th className="text-center">Deletar</th>
                    </tr>
                </thead>
                <tbody>
                    {eventos && <LinhasEventos eventos={eventos} />}
                </tbody>
            </table>
        </div>
    );


    return (
        <section className="painel-eventos">
            <div className="titulo-painel ">
                <h2>Cadastrar Eventos</h2>
            </div>
            <div className="col-md-10 col-sm-12">
                <div className="box-painel">
                    <Form>
                        <div className="row" >
                            <div className="row" >
                                <div className="col-md-6 col-sm-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Texto Pesquisa</Form.Label>
                                        <Form.Control type="text" onChange={(event) => setStringPesquisa(event.target.value)} value={stringPesquisa} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <div className="d-grid gap-1">
                                        <label>&nbsp;</label>
                                        <Button variant="primary" className="botao-custom-unimed" onClick={() => listar()}>Pesquisar </Button>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <div className="d-grid gap-1">
                                        <label>&nbsp;</label>
                                        <Button variant="primary" className="botao-custom-unimed" onClick={() => navigate("/configuracoes/cadastro-evento/form")}>Incluir </Button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Form>
                    <div className="row">
                        {resultApi && <ListEventos eventos={resultApi.eventos} />}
                        {resultApi.eventos && <div className="col d-flex align-items-center">Total de Registros: {resultApi.qtdeRegistrosTotais} </div>}
                        {resultApi.eventos && <Pagination limit={LIMIT} total={resultApi.qtdeRegistrosTotais} pagina={pagina} setPagina={setPagina} />}
                        {error && <div className="row"><span className="alert-error" >{error}</span></div>}
                        <div className="row"><br /><br /></div>
                    </div>
                </div>
            </div >
        </section >
    );

}

export default PainelCrudEvento;