import React from "react";
import "./EventoEncerrado.css";

export function EventoEncerrado ( { nomeEvento, nomeAtividade } ) {
    return (
        <div className="evento-encerrado">
            <h3>O evento já encerrou...</h3>
            <p className="descricao-evento">{nomeEvento} { nomeAtividade && ` - ${nomeAtividade}`}</p>
        </div>
    )
}