import ErrorActionTypes from "./action-types.ts";

const stateInicial = {
    error: "",
};

const errorReducer = (state = stateInicial, action) => {
    switch (action.type) {
        case ErrorActionTypes.ERROR:
            return { ...state, error: action.payload.error };
        default: 
            return state;
    }
}

export default errorReducer;