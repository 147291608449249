import ProgramacaoActionTypes from "./action-types.ts";

const stateInicial = {
    programacao: {},
};

const programacaoReducer = (state = stateInicial, action) => {
    switch (action.type) {
        case ProgramacaoActionTypes.PROGRAMACAO:
            return { ...state, programacao: action.payload.programacao };
        default: 
            return state;
    }
}

export default programacaoReducer;