import MonitoramentoActionTypes from "./action-types.ts";

const stateInicial = {
    monitoramento: "OPERADOR",
};

const monitoramentoReducer = (state = stateInicial, action) => {
    switch (action.type) {
        case MonitoramentoActionTypes.MONITORAMENTO:
            return { ...state, monitoramento: action.payload.monitoramento };
        default: 
            return state;
    }
}

export default monitoramentoReducer;