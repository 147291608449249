import RegistroPresencaActionTypes from "./action-types.ts";

const stateInicial = {
    registroPresenca: {},
};

const registroPresencaReducer = (state = stateInicial, action) => {
    switch (action.type) {
        case RegistroPresencaActionTypes.PRESENCA:
            return { ...state, registroPresenca: action.payload.registroPresenca };
        default: 
            return state;
    }
}

export default registroPresencaReducer;